import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Main from "../layouts/Main";
import Container from "../components/Container";
import {
  Content,
  FooterNewsletter,
  Hero,
  SidebarArticles,
  SidebarNewsletter,
  SimilarStories,
} from "../views/BlogArticle/components";
import { Helmet } from "react-helmet";

const BlogArticle = ({ pageContext }) => {
  const { blog } = pageContext;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.description} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.description} />
        <meta property="og:image" content={blog.image} />
        <meta
          property="og:url"
          content={`https://www.sonnul.com/blog-article/${blog.slug}`}
        />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.description} />
        <meta name="twitter:image" content={blog.image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box>
        <Hero
          title={blog.title}
          author={blog.author}
          date={blog.date}
          image={blog.image}
        />
        <Container>
          <Grid item xs={12} md={8}>
            <Content author={blog.author} date={blog.date} body={blog.body} />
          </Grid>
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <SimilarStories />
        </Container>
      </Box> */}
    </Main>
  );
};

export default BlogArticle;
