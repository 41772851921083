/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { Typography, Divider , Button} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Content = (props) => {
   const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const doc = new DOMParser().parseFromString(props.body, "text/html");
    setHtmlContent(doc.body.outerHTML);
  }, [props.body]);


  return (
    <Box>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box>
            <Typography fontWeight={600}>
              Ready to elevate your brand's online presence? Schedule a
              call for a free quote!
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box marginLeft={0.5}>
            <Button
              component="a"
              variant="text"
              color="primary"
              size="large"
              href="/schedule-call"
              sx={{
                fontWeight: 700,
                width: "fit-content",
                paddingX: 0,
                letterSpacing: 1.6,
              }}
            >
              SCHEDULE CALL{" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 22,
                  height: 22,
                  borderRadius: "50%",
                  border: "2px solid",
                  marginLeft: 1,
                }}
              >
                <ChevronRightIcon />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
