import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import Container from 'components/Container';

const Hero = (props) => {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });


  return (
    <Box height={"300px"} bgcolor={"background.lightMain"}>
      <Container zIndex={2}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 400,
              color: "text.main",
              marginBottom: 2,
            }}
          >
            {props.title}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <ListItemText
              sx={{ margin: 0 }}
              primary={props.author}
              secondary={props.date}
              primaryTypographyProps={{
                variant: "h6",
                sx: { color: "common.black" },
              }}
              secondaryTypographyProps={{
                sx: { color: "common.black" },
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
